import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Үнэт цаасны явцын мэдээг хэрхэн нийтлэх вэ?",
  "description": null,
  "author": "OTC help",
  "category": "security-disclosures",
  "date": "2022-04-08T00:00:00.000Z",
  "featured": true,
  "tags": [],
  "order": 1
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Үнэт цаасны явцын мэдээг хэрхэн нийтлэх вэ?`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Үнэт цаасны явцын мэдээг зөвхөн тухайн үнэт цаасыг системд бүртгүүлэн үүсгэсэн андеррайтер оруулах эрхтэй байна.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Үндсэн цэсний `}<strong parentName="p">{`[Security Disclosure]`}</strong>{` хэсэгт нэвтрэн баруун дээд булан дахь `}<strong parentName="p">{`[New Disclosure]`}</strong>{` товчийг дарж холбогдох явцын мэдээ, мэдээллийг нийтлэн оролцогчдод мэдээллэнэ.`}</p>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`[Security]`}</strong>{` хэсэгт жагсаалтаас тохирох үнэт цаасыг сонгоно`}</li>
          <li parentName="ul"><strong parentName="li">{`[Title]`}</strong>{` хэсэгт мэдээ, мэдээллийн гарчиг оруулна`}</li>
          <li parentName="ul"><strong parentName="li">{`[Description]`}</strong>{` хэсэгт мэдээллийн товч тайлбар оруулна`}</li>
          <li parentName="ul"><strong parentName="li">{`[Form type]`}</strong>{` хэсэгт `}<strong parentName="li">{`[File]`}</strong>{` буюу бэлэн файл хавсаргах эсвэл `}<strong parentName="li">{`[Form]`}</strong>{` хэсгийг дарж платформ дахь нэмэлт мэдээллийн хэсгүүдийг бөглөн `}<strong parentName="li">{`[Publish]`}</strong>{` дарж тайлан мэдээг нийтэлнэ.`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Нийтлэгдсэн явцын мэдээг `}<strong parentName="p">{`[Security]`}{`-`}{`[Disclosure]`}</strong>{` хэсэг болон үндсэн цэсний `}<strong parentName="p">{`[Security Disclosure]`}</strong>{` хэсгээс нийтлэгдсэн хугацаа, нийтэлсэн оролцогчийн зэрэг мэдээлэлтэйгээр харах боломжтой.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Нийтлэгдсэн тайлан мэдээг шинэчлэх, засварлах шаардлагатай гэж үзсэн тохиолдолд явцын мэдээний баруун булан дахь `}<strong parentName="p">{`[Request Update]`}</strong>{` товчийг дарж нийтэлсэн оролцогчид хүсэлт илгээнэ.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      